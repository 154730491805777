import React, { useEffect, useState } from 'react';
import Header from '../layouts/header';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import Footer from '../layouts/footer';

export default function TeamAttnSummery() {
    const [datas, setData] = useState([]);
    const [employee, setEmployee] = useState('');
    const [attendance, setAttendance] = useState('');
    const location = useLocation();
    useEffect(() => {
        if(localStorage.getItem('token')){
        const data = {
            emp_id    : location.state.id
        }
        axios.post('employee/attendance/summery', data)
        .then((response) => {
            let getData = response.data.data;
            console.log(response.data);
            setData(getData);
            setEmployee(response.data.employeeData)
            setAttendance(response.data.employeeData.attendance)
        })
        }else{
        window.location.replace('login');
        }
    }, []);
  return (
    <>
        <Header />
        <div class="row">
            <div class="col-sm-12 col-12">
                <table class="table table-borderless">
                    <tr>
                        <th colSpan={3}>
                            <h5 className='text-center'> {employee.f_name} </h5>
                            <p className='text-dark text-center'> {employee.designation} </p>
                        </th>
                    </tr>
                {datas.map((data, i) =>
                    <tr>
                        <th><div style={{width:'15px', height: '15px',backgroundColor: data.color,borderRadius: '100%'}}></div></th>
                        <th>{data.title}</th>
                        <th>{data.days} {data.extention}</th>
                    </tr>
                )}
                    <tr>
                        <th colSpan={3} style={{ backgroundColor : 'gray'}}>
                            {attendance && attendance.checkIn ? 
                                <>
                                    <p className='text-dark text-center'> Check In : { attendance.checkIn } </p>
                                    <p className='text-dark text-center'> Check Out : { attendance.checkOut } </p>
                                </>
                            : 
                                <>
                                    <h2 className='text-center text-white' style={{background: 'gray'}}> { attendance ? attendance.status : null } </h2>
                                </>
                            }
                            
                        </th>
                    </tr>
                </table>
            </div>
        </div>
        <Footer />
    </>
  );
}
