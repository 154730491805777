import axios from 'axios';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function LateReason() {
    const location = useLocation();
    const navigate = useNavigate();
    console.log('');
    const [reason, setReason ]  = useState('');
    const submit = () => {
        let data = {
            token       : localStorage.getItem('token'),
            id          : location.state.data.id,
            reason      : reason
        };
        axios.post('employee/attendance/late/reason', data)
        .then((response) => {
            let resData = response.data.response;
            console.log(resData.status);
            
            if(resData.status == 200){
                alert(resData.message);
                navigate(-1);
            }else{
                alert(resData.message);
            }
            
        })
        
    }
  return (
    <>
        <div style={{ backgroundColor : '#cc0133',padding: '30px 20px 10px 20px', cursor : 'pointer', color : 'white'}} onClick={ () => navigate('/attendance')}><p> <i className="fas fa-arrow-left"></i> <span style={{ paddingLeft: '20px'}}> Leave </span></p> </div>
        <div className='container'>
            <div className='row pt-5'>
                
                <div className='col-12'>
                    <div className='form-group mb-2'>
                        <label> Reason</label>
                        <textArea className="form-control" name="reason" rows="5" onChange={(e) => { setReason(e.target.value)}} value={reason}></textArea>
                    </div>
                </div>
                <div className='col-12 mt-3'>
                    <button className='btn btn-danger w-100' onClick={submit}> Submit </button>
                </div>
            </div>
        </div>
    </>
  );
}
