import React from 'react';
import Footer from '../layouts/footer';
import Header from '../layouts/header';
import { Link } from 'react-router-dom/dist';

export default function Dashboard() {
  return (
    <>
        <Header />
        <div className='container'>
            <div className="row app" style={{ padding: '0px 20px 100px 20px'}}>
                <div className="col-sm-6 col-6" style={{padding: '10px 10px'}}>
                    <Link to="/profile">
                    <div className="app-nav">
                        <div className="contents">
                            <i className="fas fa-user"></i>
                            <p> Profile </p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col-sm-6 col-6" style={{padding: '10px 10px'}}>
                    <Link to="/phonebook">
                        <div className="app-nav">
                            <div className="contents">
                                <i className="fas fa-phone"></i>
                                <p> Phonebook </p>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-sm-6 col-6" style={{padding: '10px 10px'}}>
                    <Link to="/leaves">
                        <div className="app-nav">
                            <div className="contents">
                                <i className="fas fa-file"></i>
                                <p> Leaves </p>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-sm-6 col-6" style={{padding: '10px 10px'}}>
                    <Link to="/attendance">
                        <div className="app-nav">
                            <div className="contents">
                                <i className="fas fa-business-time"></i>
                                <p> Attendance </p>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-sm-6 col-6" style={{padding: '10px 10px'}}>
                    <Link to="/payroll">
                        <div className="app-nav">
                            <div className="contents">
                                <i className="fa-solid fa-hand-holding-dollar fa-fw"></i>
                                <p> Payroll </p>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-sm-6 col-6" style={{padding: '10px 10px'}}>
                    <Link to="/team">
                        <div className="app-nav">
                            <div className="contents">
                                <i className="fas fa-users"></i>
                                <p> My Team </p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
        <Footer />
    </>
  );
}
