import React from 'react';
import { Badge,Button } from 'react-bootstrap';
import Header from '../layouts/header';
import Footer from '../layouts/footer';
import { Link } from 'react-router-dom/dist';

export default function Home() {
  const d = new Date()
  const endTime = d.toLocaleString([], {
    hour        : 'numeric',
    minute      : 'numeric',
    hour12      : true,
    timeZone    : 'Asia/Dhaka'
  })
  return (
    <div className="container-fluid">
      <div className="row">
          <div className="col-md-12 col-offset-md-3 p-0">
              <div className="app">
                  <Header />
                  <div className="app-body">
                      <div className="attendance-contents">
                        <div className="current-time">
                            <h2> { endTime }</h2>
                        </div>
                        
                        <Link to="/attendance">
                        <div className="attendance">
                            <i className="fa-solid fa-right-to-bracket icon"></i>
                            <div className="attendance-status">
                                <h4 style={{ color : 'black'}}> Done for today ?</h4>
                                <h3 style={{ color : 'black'}}> Check in\out </h3>
                            </div>
                        </div>
                        </Link>
                      </div>
                  </div>
                  <Footer />
              </div>
          </div>
      </div>
    </div>
  );
}
