import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Home from './components/home/home';
import AppRoutes from './components/layouts/appRoutes';
import Login from './components/auth/Login';

function App() {
  let data;
  
  if(localStorage.getItem('token')){
    data = <AppRoutes />;
  }else{
    data = <Login />;
  }
  return (
    <div className="App">
        <div className="root">
            { data }
        </div>
    </div>
  );
}

export default App;
