import React from 'react';
import NavMain from './navMain';

export default function Footer() {
  return (
    <div className="footer">
        <div className="footer-contents">
            <NavMain />
        </div>
    </div>
  );
}
