import React, { useEffect, useState } from 'react';
import Footer from '../layouts/footer';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../layouts/header';
import axios from 'axios';

export default function Team() {
    const navigate          = useNavigate();
    const [datas, setData]  = useState([]);
    useEffect(() => {
        if(localStorage.getItem('token')){
        const data = {
            token    : localStorage.getItem('token')
        }
        axios.post('employee/contact/team', data)
        .then((response) => {
            let data = response.data.data;
            console.log(data);
            setData(data);
        })
        }else{
            window.location.replace('login');
        }
    }, []);
    const getDetails = (id) => {
        navigate('/team-attendance-summery', {state : {id:id}})
    }
  return (
    <>
        <Header />
        <div className="row mt-1">
            <div className="col-sm-6 col-6 p-0"> 
                <Link to="/team"><p className="switcher green"> My Team </p></Link>
            </div>
            <div className="col-sm-6 col-6 p-0"> 
                <Link to="/team-leave"><p className="switcher red"> Team Leave </p></Link>
            </div>
        </div>
        {datas.map((data, i) =>
        <div className='row' onClick={() => getDetails(data.id)}>
            <div style={{ display : 'flex',padding: '20px 30px',alignItems: 'center', marginBottom : '10px'}}>
                <div style={{ aspectRatio : '1', borderRadius : '100px', overflow : 'hidden', width: '20%' }}>
                    <img src={data.image} style={{ width : '100%'}}/>
                </div>
                <div className='px-3' style={{ width: '60%'}}>
                    <h5 style={{ fontSize: '18px',fontWeight: 'bold'}}> { data.f_name } </h5>
                    <p style={{ color : 'black'}}>{ data.email }</p>
                    <p style={{ color : 'black'}}>{ data.phone }</p>
                </div>
                <div style={{ width: '20%'}}>
                    <h5 style={{ backgroundColor : data.attendance ? data.attendance.color : 'white',color: 'white',borderRadius: '20px',textAlign: 'center',fontSize: '14px',padding: '7px'}}> <span> {data.attendance.status} </span> </h5>
                </div>
            </div>
        </div>
        )}
        <Footer />
    </>
  );
}
