import React, { useEffect, useState } from 'react';
import Header from '../layouts/header';
import Footer from '../layouts/footer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Notice() {
  const [datas, setData]          = useState([]);
    const navigate                = useNavigate();
    useEffect(() => {
        if(localStorage.getItem('token')){
        const data = {
            token    : localStorage.getItem('token')
        }
        axios.post('employee/notice', data)
        .then((response) => {
            let data = response.data.data;
            console.log(data);
            setData(data);
        })
        }else{
            window.location.replace('login');
        }
    }, []);
    const details = (e) => {
      navigate('/notice-details', { state : { id:e }});
    }
  return (
    <>
        <Header />
        <div className='container'>
          <div className='row'>
            <div className='col-12 py-3'>
              <table className='table'>
                {datas.map((data,i) => 
                  <tr onClick={() => details(data.id)} style={{ cursor : 'progress'}}>
                    <td>
                      <h2 style={{padding: '0px',margin: '0px', fontWeight : 'bold'}}> {data.title} </h2>
                      <p> {data.sub_title} </p>
                    </td>
                  </tr>
                )}
              </table>
            </div>
          </div>
        </div>
        <Footer />
    </>
  );
}
