import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function NoticeDetails() {
    const [datas, setData]  = useState([]);
    const navigate          = useNavigate();
    const location          = useLocation();
    useEffect(() => {
        if(localStorage.getItem('token')){
        const data = {
            id    : location.state.id
        }
        axios.post('employee/notice', data)
        .then((response) => {
            let data = response.data.data;
            console.log(data);
            setData(data);
        })
        }else{
            window.location.replace('login');
        }
    }, []);
    return (
    <>
        <div style={{ backgroundColor : '#cc0133',padding: '30px 20px 10px 20px', cursor : 'pointer', color : 'white'}} onClick={ () => navigate(-1)}><p> <i className="fas fa-arrow-left"></i> <span style={{ paddingLeft: '20px'}}> Notice </span></p> </div>

        <div className='container'>
            <div className='row py-4'>
                <div className='col-12'>
                    <h2 className='text-center'>{datas.title}</h2>
                    <p className='text-center pb-3'>{datas.sub_title}</p>
                </div>
                <div className='col-12'>
                    <div dangerouslySetInnerHTML={{__html: datas.description}}></div>
                </div>
            </div>
        </div>
    </>
    );
}
