import React, { useEffect, useState } from 'react';
import Header from '../layouts/header';
import Footer from '../layouts/footer';
import { Link } from 'react-router-dom/dist';
import axios from 'axios';
import AttendanceList from './AttendanceList';

export default function Summery() {
    const [datas, setData] = useState([]);
    useEffect(() => {
        if(localStorage.getItem('token')){
        const data = {
            token    : localStorage.getItem('token')
        }
        axios.post('employee/attendance/summery', data)
        .then((response) => {
            let getData = response.data.data;
            // console.log(response.data);
            setData(getData);
        })
        }else{
        window.location.replace('login');
        }
    }, []);
  return (
    <>
        <Header />

        <div className="row mt-1">
            <div className="col-sm-6 col-6 p-0"> 
                <Link to="/attendance"><p className="switcher red"> Attendance </p></Link>
            </div>
            <div className="col-sm-6 col-6 p-0"> 
                <Link to="/summery"><p className="switcher green"> Summery </p></Link>
            </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-12">
            <table class="table table-borderless">
            {datas.map((data, i) =>
                <tr>
                    <th><div style={{width:'15px', height: '15px',backgroundColor: data.color,borderRadius: '100%'}}></div></th>
                    <th>{data.title}</th>
                    <th>{data.days} {data.extention}</th>
                </tr>
            )}
            </table>
          </div>
        </div>
        <AttendanceList />
        <Footer />
    </>
  );
}
