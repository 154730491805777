import React, { useEffect, useRef, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Header from '../layouts/header';
import Footer from '../layouts/footer';
import { Link, useNavigate } from 'react-router-dom/dist';
import axios from 'axios';

export default function Attendance() {
    const navigate          = useNavigate();
    const [type, setType ]  = useState('');
    const [atnText, setAtnText ]  = useState('');
    const [ip, setIp ]      = useState('');
    const [lat, setLat ]    = useState('');
    const [long, setLong ]  = useState('');
    const [address, setAddress ]  = useState('');
    const [attendanceData, setAttendanceData] = useState();
    const location = window.navigator && window.navigator.geolocation;
    const d = new Date()
    const endTime = d.toLocaleString([], {
        hour        : 'numeric',
        minute      : 'numeric',
        hour12      : true,
        timeZone    : 'Asia/Dhaka'
    })
    useEffect(() => {
        if (location) {
            location.getCurrentPosition((position) => {
                setLat(position.coords.latitude);
                setLong(position.coords.longitude);
                console.log(lat+'-'+long)
                axios.get('https://geocode.maps.co/reverse?lat='+position.coords.latitude+'&lon='+position.coords.longitude+'&api_key=6605d59e59375862283956cuo3e98c9', {
                    headers: {
                        'Content-Type': 'application/json'
                    },      
                })      
                .then((response) => {
                    console.log(response.data.display_name)
                    setAddress(response.data.display_name);
                })
                .catch((error) => {
                    console.log('error',error.response)

                })
            }, (error) => {
                console.log('error');
            })
        }
        if(localStorage.getItem('token')){
            axios.get('https://api.ipify.org?format=json', {
                headers: {
                    'Content-Type': 'application/json'
                },      
            })      
            .then((response) => {
                console.log(response.data)
                setIp(response.data.ip)
            })
            .catch((error) => {
                alert('error',error.response)

            })
            const data = {
                token : localStorage.getItem('token')
            }
            axios.post('employee/attendance/check', data)
            .then((response) => {
                let getData = response.data.data;
                console.log(getData);
                setAttendanceData(getData);
                
                if(getData.checkin && getData.checkout){
                    setAtnText('');
                }else if(getData.checkin && !getData.checkout){
                    setAtnText('Check Out');
                }else{
                    setAtnText('Check In');
                }
            })

        }else{
            window.location.replace('login');
        }

    }, []);
    const reloadip = () => {
        if (location) {
            location.getCurrentPosition((position) => {
                setLat(position.coords.latitude);
                setLong(position.coords.longitude);
                console.log(lat+'-'+long)
                axios.get('https://geocode.maps.co/reverse?lat='+position.coords.latitude+'&lon='+position.coords.longitude+'&api_key=6605d59e59375862283956cuo3e98c9', {
                    headers: {
                        'Content-Type': 'application/json'
                    },      
                })      
                .then((response) => {
                    console.log(response.data)
                    setAddress(response.data.display_name);
                })
                .catch((error) => {
                    console.log('error',error.response)

                })
            }, (error) => {
                console.log('error');
            })
        }
        if(localStorage.getItem('token')){
            axios.get('https://api.ipify.org?format=json', {
                headers: {
                    'Content-Type': 'application/json'
                },      
            })      
            .then((response) => {
                console.log(response.data)
                setIp(response.data.ip)
            })
            .catch((error) => {
                alert('error',error.response)

            })

        }else{
        window.location.replace('login');
        }
    }
    const giveAttendance = () => {
        // alert('i am here ');
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to do this.',
            buttons: [
              {
                label: 'Yes',
                onClick : appAttendance
              },
              {
                label: 'No'
              }
            ]
        });
    }
    const appAttendance = () => {
        if(localStorage.getItem('token')){
        const data = {
            token   : localStorage.getItem('token'),
            lat     : lat,
            lon     : long,
            address : address,
            type    : type
        }
        axios.post('employee/attendance/create', data)
        .then((response) => {
            let getData = response.data.data;
            console.log(getData);
            if(response.data.response.status == 201){
                setAttendanceData(getData);
                navigate('/late-reason', {state : {data:getData}});

            }else if(response.data.response.status == 200){
                setAttendanceData(getData);
                if(getData.checkin && getData.checkout){
                    setAtnText('');
                }else if(getData.checkin && !getData.checkout){
                    setAtnText('Check Out');
                }else{
                    setAtnText('Check In');
                }
            }else{
                alert('went wrong')
            }
        })
        }else{
            window.location.replace('login');
        }
    }
    // const startCounter = () => {
    //     console.log('start');
    //     if (intervalRef.current) return;
    //     intervalRef.current = setInterval(() => {
    //       setCounter((prevCounter) => prevCounter + 1);
    //     }, 100);
    //   };
    
    // const stopCounter = () => {
    //     console.log('stop');
    //     if (intervalRef.current) {
    //       clearInterval(intervalRef.current);
    //       intervalRef.current = null;
    //     }
    // };
  return (
    <>
        <Header />
        <div className="row mt-1">
            <div className="col-sm-6 col-6 p-0"> 
                <Link to="/attendance"><p className="switcher green"> Attendance </p></Link>
            </div>
            <div className="col-sm-6 col-6 p-0"> 
                <Link to="/summery"><p className="switcher red"> Summary </p></Link>
            </div>
            <div className="col-12 py-2">
                <div style={{ display : 'flex',padding: '0px 10px'}}>
                    <p className="address" style={{}}>  {lat ? address : ip}</p>
                    <p onClick={reloadip}><i className="fa-solid fa-rotate fa-fw text-dark"></i></p>
                </div>
               
            </div>
        </div>
        <div className="row">
            <div className="attn-place">
                <button className="attn-btn " onClick={() => setType(1)} style={{ backgroundColor : type == 1 ? 'green' : '', color : type == 1 ? 'white' : 'black' }}> Home </button>
                <button className="attn-btn" onClick={() => setType(2)} style={{ backgroundColor : type == 2 ? 'green' : '', color : type == 2 ? 'white' : 'black' }}> Field </button>
            </div>
        </div>
        <div className="row">
            <center>
            <div className="col-6 py-3">
                <h2 style={{fontSize: '35px',fontWeight: 'bold',padding: '20px 0px'}}> {endTime}</h2>
                {/* <div className="btn-attendance" onMouseDown={startCounter} onMouseUp={stopCounter} onMouseLeave={stopCounter}> */}
                {atnText ? 
                    <div className="btn-attendance" onClick={ giveAttendance }>
                        <h3 className="text-center text-white w-100"> {atnText} </h3>
                    </div> 
                :   
                    <div className="">
                        <h2> Thank you  </h2>
                        <h3 className="text-center  w-100">  You are all done for today ! </h3>
                    </div>
                }
            </div>
            </center>
        </div>
        <Footer />
    </>
  );
}
