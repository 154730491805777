import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function AttendanceList() {
    const [datas, setData] = useState([]);
    useEffect(() => {
        if(localStorage.getItem('token')){
        const data = {
            token    : localStorage.getItem('token')
        }
        axios.post('employee/attendance/list', data)
        .then((response) => {
            let getData = response.data.data;
            console.log(getData);
            setData(getData);
            
        })
        }else{
            window.location.replace('login');
        }
    }, []);
    const getReason = (id) => {
        alert(id);
    }
  return (
    <>
        <div class="row" style={{marginBottom: '100px'}}>
          <div class="col-12">
              <h3 class="text-center py-3"> Daily Report</h3>
          </div>
          <div class="col-12">
              <table class="table tbl-attn">
                {datas.map((item, i) =>

                <tr>
                    <td>
                        <h4 style={{ fontSize : '14px'}}>{ item.status }</h4>
                        <h5 style={{ fontSize : '14px'}}>{ item.date }</h5>
                    </td>
                    <td>
                        {item.tag == 1 ? 
                            <>
                                <p>in: <span style={{backgroundColor : item.status == 'Late' ? 'orange' : 'green', padding : '5px 10px',color : 'white', fontSize : '14px'}}>{item.checkin}</span>
                                    {item.status == 'Late' ? 
                                        <i className="fas fa-file" style={{ color : '#CC0133', padding : 10}} onClick={ () => getReason(item.late_reason) }></i> :
                                    ''}

                                    {item.in_location ? 
                                        <i className="fas fa-map" style={{ color : '#008001', padding : 10}} onClick={ () => getReason(item.in_location) }></i> :
                                    ''}

                                </p>
                                {
                                item.checkout ? 
                                    <p>out: <span style={{backgroundColor : item.left_status == 'Early' ? 'orange' : 'green', padding : '5px 10px',color : 'white', fontSize : '14px'}}>{item.checkout}</span>
                                        {item.status == 'Early' ? 
                                            <i className="fas fa-file" style={{ color : '#CC0133', padding : 10}} onClick={ () => getReason(item.early_reason) }></i> :
                                        ''}
                                        {item.out_location ? 
                                            <i className="fas fa-map" style={{ color : '#008001', padding : 10}} onClick={ () => getReason(item.out_location) }></i> :
                                        ''}
                                    </p>
                                : '' 
                                }
                            </>
                        :
                            <>
                                <p><span style={{backgroundColor : 'red', color : 'white', paddingVertical : 5, paddingHorizontal : 10, marginVertical : 3, fontSize : 17, fontWeight : 'bold', borderRadius : 10}}>{item.holiday}</span></p>
                            </>
                        }
                        
                    </td>
                </tr>
                )}
                  {/* <tr>
                      <td>
                          <h4>Friday</h4>
                          <h5>2024-03-01</h5>
                      </td>
                      <td>
                          <p>in: <span style={{backgroundColor : 'rgb(8, 129, 48)', padding : '5px 10px',color : 'white'}}>11:05 AM</span></p>
                          <p>out: <span style={{backgroundColor : 'rgb(8, 129, 48)', padding : '5px 10px',color : 'white'}}>6:30 PM</span></p>
                      </td>
                  </tr> */}
              </table>
          </div>
        </div>
    </>
  );
}
