import React from 'react';
import Home from '../home/home';
import { Route, Routes } from 'react-router-dom/dist';
import Dashboard from '../dashboard/Dashboard';
import Notice from '../notice/Notice';
import Attendance from '../attendance/Attendance';
import Summery from '../attendance/Summery';
import PhoneBook from '../phonebook/PhoneBook';
import LeaveList from '../leave/LeaveList';
import Login from '../auth/Login';
import LeaveApply from '../leave/LeaveApply';
import LeaveForm from '../leave/LeaveForm';
import Profile from '../auth/Profile';
import LeaveDetails from '../leave/LeaveDetails';
import Gretings from '../home/gretings';
import LateReason from '../attendance/LateReason';
import Team from '../team/team';
import TeamLeave from '../team/teamLeave';
import TeamAttnSummery from '../team/teamAttnSummery';
import TeamLeaveDetails from '../team/teamLeaveDetails';
import ProfileEdit from '../auth/ProfileEdit';
import NoticeDetails from '../notice/NoticeDetails';

export default function AppRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} exact/>
        <Route path="/home" element={<Home />} exact/>
        <Route path="/gretings" element={<Gretings />} exact/>
        <Route path="/dashboard" element={<Dashboard />} exact/>
        <Route path="/notice" element={<Notice />} exact/>
        <Route path="/notice-details" element={<NoticeDetails />} exact/>
        <Route path='/attendance' element={<Attendance />} exact />
        <Route path='/late-reason' element={<LateReason />} exact />
        <Route path='/summery' element={<Summery />} exact />
        <Route path='/phonebook' element={<PhoneBook />} exact />
        <Route path='/leaves' element={<LeaveList />} exact />
        <Route path='/leave-apply' element={<LeaveApply />} exact />
        <Route path='/leave-form' element={<LeaveForm />} exact />
        <Route path='/leave-details' element={<LeaveDetails />} exact />
        <Route path='/team' element={<Team />} exact />
        <Route path='/team-leave' element={<TeamLeave />} exact />
        <Route path='/team-attendance-summery' element={<TeamAttnSummery />} exact />
        <Route path='/team-leave-details' element={<TeamLeaveDetails />} exact />

        <Route path='/login' element={<Login />} exact />
        <Route name="profile" path='/profile' element={<Profile />} exact />
        <Route name="profile-edit" path='/profile-edit' element={<ProfileEdit />} exact />
      </Routes>
    </>
  );
}
