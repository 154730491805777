import React from 'react';
import { Link } from "react-router-dom";

export default function NavMain() {
  const currentUrl = window.location.pathname;
  console.log(currentUrl);
  let home      = '';
  let dashboard = '';
  let notice    = '';
  if(currentUrl === '/home'){
    home      = 'active';
    dashboard = '';
    notice    = '';
  }else if(currentUrl === '/dashboard'){
    home      = '';
    dashboard = 'active';
    notice    = '';
  }else if(currentUrl === '/notice'){
    home      = '';
    dashboard = '';
    notice    = 'active';
  }
  return (
    <>
    <div className={'footer-nav '+home}>
        <Link to="/home">
          <i className="fas fa-home w-100" style={{fontSize: '20px'}}></i>
          <p>Home</p>
        </Link>
    </div>
    <div className={'footer-nav '+dashboard}>
        
        <Link to="/dashboard">
          <i className="fas fa-dashboard w-100" style={{fontSize: '20px'}}></i>
          <p>Dashboard</p>
        </Link>
    </div>
    <div className={'footer-nav '+notice}>
        <Link to="/notice">
          <i className="fas fa-bell w-100" style={{fontSize: '20px'}}></i>
          <p>Notice</p>
        </Link>
    </div>
    </>
  );
}
