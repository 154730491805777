import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export default function Header() {
  const navigate                      = useNavigate();
  const [name, setName]               = useState('');
  const [designation, setDesignation] = useState('');
  const [image, setImage] = useState('');

  useEffect(() => {
    if(localStorage.getItem('token')){
      const data = {
        token    : localStorage.getItem('token')
      }
      axios.post('employee/check/login', data)
      .then((response) => {
          let data = response.data.data;
          let employee = data.employee;
          console.log(data.employee);
          setName(employee.f_name);
          setDesignation(employee.designation);
          setImage(employee.image);
      })
    }else{
      window.location.replace('login');
    }
  }, []);

  return (
    <div className="header">
        <div className="header-contents">
            <div className="profile">
              <img src={image} onClick={() => navigate('/profile')} style={{ cursor : 'pointer'}}/>
            </div>
            <div className="profile-contents">
                <h3> { name } </h3>
                <p> { designation } </p>
                <Link to="/profile" className="btn-profile"> Profile </Link>
            </div>
        </div>
    </div>
  );
}
