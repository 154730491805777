import React from 'react';
import { Controls, Player } from '@lottiefiles/react-lottie-player';
import { useNavigate } from 'react-router-dom';

export default function Gretings() {
    const navigate = useNavigate();
    const { innerWidth: width, innerHeight: height } = window;
  return (
    <>
        <div className='container'>
            <div className='row' style={{ height : height, alignContent: 'center' }}>
                <div className='col-12'>
                    <Player
                        autoplay
                        loop
                        src="https://lottie.host/7f3b6cbf-f76c-4c52-9e48-2773646077c5/WIr4FglEPY.json"
                        style={{  height: '300px',}}
                        >
                        <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                    </Player>
                </div>
                <div className='col-12'>
                    <p className='text-center pb-2'> version : 1.0.1 </p>
                    <button className='btn btn-danger w-100' onClick={ () => navigate('/home')} style={{ bottom: '50px' }}> Home </button>
                    <button className='btn btn-success w-100 mt-3' onClick={ () => navigate('/attendance')} style={{ bottom: '50px' }}> Attendance </button>
                </div>
            </div>
        </div>
    </>
  );
}
