import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../layouts/footer';
import axios from 'axios';

export default function TeamLeave() {
    const navigate          = useNavigate();
    const [datas, setData]  = useState([]);
    useEffect(() => {
        if(localStorage.getItem('token')){
        const data = {
            token    : localStorage.getItem('token')
        }
        axios.post('employee/contact/team/leave', data)
        .then((response) => {
            let data = response.data.data;
            console.log(data);
            setData(data);
        })
        }else{
            window.location.replace('login');
        }
    }, []);
    const getDetails = (id) => {
        navigate('/team-leave-details', {state : {id:id}});
    }
  return (
    <>
        <div style={{ backgroundColor : '#cc0133',padding: '30px 20px 10px 20px', cursor : 'pointer', color : 'white'}} onClick={ () => navigate(-1)}><p> <i className="fas fa-arrow-left"></i> <span style={{ paddingLeft: '20px'}}> Team </span></p> </div>
        <div className="row mt-1">
            <div className="col-sm-6 col-6 p-0"> 
                <Link to="/team"><p className="switcher red"> My Team </p></Link>
            </div>
            <div className="col-sm-6 col-6 p-0"> 
                <Link to="/team-leave"><p className="switcher green"> Team Leave </p></Link>
            </div>
        </div>
        <div className='row'>
            {datas.map((data, i) =>
                <div style={{ display : 'flex',padding: '20px 30px',alignItems: 'center', marginBottom : '10px'}} onClick={() => getDetails(data.id)}>
                    <div style={{ aspectRatio : '1', borderRadius : '100px', overflow : 'hidden', width: '20%' }}>
                        <img src={data.employee.image} style={{ width : '100%'}}/>
                    </div>
                    <div className='px-3' style={{ width: '60%'}}>
                        <h5 style={{ fontSize: '18px',fontWeight: 'bold'}}> {data.employee.f_name} </h5>
                        <p style={{ color : 'black'}}>{data.type.type} </p>
                        <p style={{ color : 'black'}}>{data.total} days</p>
                    </div>
                    <div style={{ width: '20%'}}>
                        <h5 style={{ background: data.color,color: 'white',borderRadius: '20px',textAlign: 'center',fontSize: '14px',padding: '7px'}}> <span> {data.status} </span> </h5>
                    </div>
                </div>
            )}
        </div>
        <Footer />
    </>
  );
}
