import React, { useEffect, useState } from 'react';
import Header from '../layouts/header';
import Footer from '../layouts/footer';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import LeaveForm from './LeaveForm';

export default function LeaveApply() {
    const navigate = useNavigate();
    const [datas, setData] = useState([]);
  useEffect(() => {
    if(localStorage.getItem('token')){
      const data = {
        token    : localStorage.getItem('token')
      }
      axios.post('employee/leave/list', data)
      .then((response) => {
          let data = response.data.data;
          console.log(data.leaveType);
          setData(data.leaveType);
      })
    }else{
      window.location.replace('login');
    }
  }, []);
  const getForm = (id) => {
    navigate(`/leave-form`,{state:{id:id}});
  }
  
  return (
    <>
        <Header />
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                {datas.map((data, i) =>
                <>
                    {data.days_left > 0 ?
                        <div className='panel' onClick={() => getForm(data.id)} style={{ cursor : 'pointer'}}>
                            <h1> {data.title} </h1>
                            <h4 style={{ color : 'white'}}> {data.days_left} </h4>
                            <p> {data.days_lim} / {data.days} </p>
                        </div>
                    :
                    <div className='panel' style={{ backgroundColor : 'gray'}}>
                        <h1> {data.title} </h1>
                        <h4 style={{ color : 'black'}}> {data.days_left} </h4>
                        <p> {data.days_lim} / {data.days} </p>
                    </div>
                    }
                    
                </>
                
                    
                )}
                </div>
            </div>
        </div>
        <Footer />
    </>
  );
}
