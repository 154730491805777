import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import FileBase64 from 'react-file-base64';

export default function ProfileEdit() {
    const navigate = useNavigate()
    const location = useLocation();
    // const data      = location.state.data;
    // console.log(data.f_name)
    const [data, setData] = useState(location.state.data);
    const [FName, setFName] = useState(data.f_name);
    const [LName, setLName] = useState(data.l_name);
    const [Email, setEmail] = useState(data.email);
    const [Phone, setPhone] = useState(data.phone);
    const [Gender, setGender] = useState(data.gender);
    const [ValGender, setValGender] = useState(data.gender_val);
    const [Image, setImage] = useState(data.image);
    const [Image64, setImage64] = useState('');
    

    const GenderOption = [
        { value: '1', label: 'Male' },
        { value: '2', label: 'Female' },
        { value: '3', label: 'Other' }
    ];
    const submit = () => {
        // alert(ValGender);
        console.log(Image64);
        if(localStorage.getItem('token')){
            let data = {
                token       : localStorage.getItem('token'),
                id          : location.state.data.id,
                name        : FName,
                lname       : LName,
                email       : Email,
                phone       : Phone,
                gender      : ValGender,
                image       : Image64 ? Image64 : null
            }
            axios.post('employee/profile/update', data)
            .then((response) => {
                let resData = response.data.data;
                console.log(resData.employee);
                // setData(resData.employee);
                navigate('/profile')
            })
        }else{
            window.location.replace('login');
        }
    }
    // getFiles(files){
    //     this.setState({ files: files })
    // }
    const getFiles = (files) => {
        let file = files.target.files[0];
        // console.log(file);
        var reader = new FileReader();
        reader.onloadend = function() {
            // console.log('RESULT', reader.result)
            setImage64(reader.result);
        }
        reader.readAsDataURL(file);
        
    }
    
  return (
    <>
    <div style={{ backgroundColor : '#cc0133',padding: '30px 20px 10px 20px', cursor : 'pointer', color: 'white'}} onClick={ () => navigate(-1)}><p> <i className="fas fa-arrow-left"></i> <span style={{ paddingLeft: '20px'}}> Profile </span></p> </div>
    <div className='container py-4'>
        <div className='row' style={{paddingBottom: '50px' }}>
            <div className='col-12'>
                <div className='form-group mb-2'>
                    <label> First Name </label>
                    <input type='text' className='form-control' onChange={(e) => { setFName(e.target.value)}} value={FName}/>
                </div>
                <div className='form-group mb-2'>
                    <label> Last Name </label>
                    <input type='text' className='form-control' onChange={(e) => { setLName(e.target.value)}} value={LName} />
                </div>
                <div className='form-group mb-2'>
                    <label> Email </label>
                    <input type='email' className='form-control'  onChange={(e) => { setEmail(e.target.value)}} value={Email}/>
                </div>
                <div className='form-group mb-2'>
                    <label> Phone </label>
                    <input type='text' className='form-control'  onChange={(e) => { setPhone(e.target.value)}} value={Phone}/>
                </div>
                <div className='form-group mb-2'>
                    <label> Gender </label>
                    <Select name="gender" options={ GenderOption } 
                        onChange={(e) => { 
                            setGender(e.label)
                            setValGender(e.value)
                        }} 
                        value={{value: ValGender, label: Gender}}/>
                </div>
                <div className='form-group mb-2'>
                    <label> Image </label>
                    <img className="mb-2" src={Image ? Image :'https://image.shutterstock.com/image-vector/businessman-avatar-profile-picture-600w-221565274.jpg' } style={{ width:'100px' }}/>
                    <input type='file' className='form-control' onChange={getFiles} />
                </div>
            </div>
            <div className='co-12 mt-5'>
                <button className='btn btn-danger w-100' onClick={ submit }> Update </button>
            </div>
        </div>
    </div>
    </>
  );
}
